// Core
import { forwardRef } from "react";
import cx from "classnames";

// Definitions
import type { InputSwitchPropsType } from "./InputSwitch.types";

// Components
import { Switch } from "antd";
import { Text } from "../../Typography/Text";

// Utils
import st from "./Styles.module.less";

export const InputSwitch = forwardRef<HTMLInputElement, InputSwitchPropsType>((props, ref) => {
  const {
    loadingFetch = false,
    disabled = false,
    size,
    textAlign = "right",
    additionalText,
    onWrapperClick,
    className,
    ...rest
  } = props;

  const checkedValue = typeof rest.checked === "boolean" ? rest.checked : rest?.value;

  const switchStyles = cx(st.switch, className);

  return (
    <div className={switchStyles} onClick={onWrapperClick}>
      {textAlign === "left" && additionalText && (
        <Text size="14" font="system" alignmentVertical="middle" className={st["text-left"]}>
          {additionalText}
        </Text>
      )}
      <Switch
        {...rest}
        checked={checkedValue}
        className={st["switch-btn"]}
        loading={loadingFetch}
        size={size}
        disabled={disabled}
        ref={ref}
      />
      {textAlign === "right" && additionalText && (
        <Text size="14" font="system" alignmentVertical="middle" className={st["text-right"]}>
          {additionalText}
        </Text>
      )}
    </div>
  );
});
InputSwitch.displayName = "InputSwitch";
