// Core
import { useFormContext } from "react-hook-form";

// Hooks
import { useTranslation } from "client/utils/i18n/client";

// Components
import { WrappedFormInputSwitch } from "components/common/WrappedFormInputSwitch";
import { InputForm } from "components/ui/FormFields/InputForm";
import { Text } from "components/ui/Typography/Text";
import { DecorDivider } from "components/common/DecorDivider";

// Utils
import st from "./styles.module.css";

type ConsentCookiesGroupItemProps = {
  name: string;
  label: string;
  vendor: string;
  expiry: string;
  description: string;
  type: string;
  isGroupValueEnabled: boolean;
  disabled?: boolean;
};

export const ConsentCookiesGroupItem = (props: ConsentCookiesGroupItemProps) => {
  const { name, label, expiry, type, vendor, description, isGroupValueEnabled, disabled } = props;

  const { control } = useFormContext();

  const { t } = useTranslation();

  return (
    <div className={st["consent-cookies-group-item"]}>
      <DecorDivider gutter="small" />
      <InputForm id={name} type="no-gutter">
        <WrappedFormInputSwitch
          name={name}
          control={control}
          additionalText={`${t("cookies:consentCookies.columns.name")}: ${label}`}
          textAlign="right"
          disabled={disabled || !isGroupValueEnabled}
        />
      </InputForm>
      <Text size="14">{`${t("cookies:consentCookies.columns.vendor")}: ${vendor}`}</Text>
      <Text size="14">{`${t("cookies:consentCookies.columns.expiry")}: ${expiry}`}</Text>
      <Text size="14">{`${t("cookies:consentCookies.columns.type")}: ${type}`}</Text>
      <Text size="14">{description}</Text>
    </div>
  );
};
