// Core
import { useFormContext } from "react-hook-form";

// Hooks
import { useTranslation } from "client/utils/i18n/client";

// Components
import { WrappedFormInputSwitch } from "components/common/WrappedFormInputSwitch";
import { InputForm } from "components/ui/FormFields/InputForm";

// Definitions
import { CONSENT_COOKIES_GROUPS } from "client/core/consent-cookies/models";
import type { ConsentCookiesType } from "client/core/consent-cookies/models";

// Utils
import { getConsentCookiesData } from "client/core/consent-cookies/data";
import { setCookiesGroupValueToItems } from "client/core/consent-cookies/utils";
import st from "./styles.module.css";

type ConsentCookiesGroupSwitcherProps = {
  groupName: keyof typeof CONSENT_COOKIES_GROUPS;
  disabled?: boolean;
};

export const ConsentCookiesGroupSwitcher = (props: ConsentCookiesGroupSwitcherProps) => {
  const { groupName, disabled } = props;

  const { t } = useTranslation();

  const { control, setValue } = useFormContext<ConsentCookiesType>();

  const { switcherLabel } = getConsentCookiesData({ type: groupName, t });

  const onClickHandler = (value: boolean) => {
    setValue(groupName, setCookiesGroupValueToItems({ groupName, value }));
  };

  return (
    <InputForm id={`${groupName}.isEnabled`} name={`${groupName}.isEnabled`} type="no-gutter">
      <WrappedFormInputSwitch
        name={`${groupName}.isEnabled`}
        control={control}
        additionalText={switcherLabel}
        textAlign="right"
        disabled={disabled}
        className={st["consent-cookies-group-switcher"]}
        onWrapperClick={(event) => {
          event.stopPropagation();
        }}
        onClick={onClickHandler}
      />
    </InputForm>
  );
};
