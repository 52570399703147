// Definitions
import {
  CONSENT_COOKIES_GROUPS,
  CONSENT_COOKIES_NECESSARY,
  CONSENT_COOKIES_STATISTICS,
} from "client/core/consent-cookies/models";
import type { TFunction } from "client/utils/i18n/types";

// Utils
import { CONSENT_COOKIE_KEY } from "client/core/consent-cookies/constants";

const getCookiesStatisticsData = (props: { t: TFunction }) => {
  const { t } = props;
  return [
    {
      key: "1",
      label: "_ga",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.ga"),
      name: CONSENT_COOKIES_STATISTICS.ga,
    },
    {
      key: "2",
      label: "_gid",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1day"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.gid"),
      name: CONSENT_COOKIES_STATISTICS.gid,
    },
    {
      key: "3",
      label: "_gat",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1minute"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.gat"),
      name: CONSENT_COOKIES_STATISTICS.gat,
    },
    {
      key: "7",
      label: "VISITOR_INFO1_LIVE",
      vendor: "youtube.com",
      expiry: t("cookies:consentCookies.cookiesMaxAge.5months27days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.visitorInfoLive"),
      name: CONSENT_COOKIES_STATISTICS.visitorInfoLive,
    },
    {
      key: "8",
      label: "YSC",
      vendor: "youtube.com",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.ysc"),
      name: CONSENT_COOKIES_STATISTICS.ysc,
    },
  ];
};

export const getCookiesNecessaryData = (props: { t: TFunction }) => {
  const { t } = props;
  return [
    {
      key: "1",
      label: "__Secure-next-auth.session-token",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthSession"),
      name: CONSENT_COOKIES_NECESSARY.secureNextAuthSession,
    },
    {
      key: "2",
      label: "__Secure-next-auth.callback-url ",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthCallback"),
      name: CONSENT_COOKIES_NECESSARY.secureNextAuthCallback,
    },
    {
      key: "3",
      label: "__Host-next-auth.csrt-token",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthCsrt"),
      name: CONSENT_COOKIES_NECESSARY.secureNextAuthCsrt,
    },
    {
      key: "4",
      label: "kmtx_sync_pi",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.kmtxSyncPi"),
      name: CONSENT_COOKIES_NECESSARY.kmtxSyncPi,
    },
    {
      key: "5",
      label: "kmtx_sync",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.kmtxSync"),
      name: CONSENT_COOKIES_NECESSARY.kmtxSync,
    },
    {
      key: "6",
      label: "sntk_test",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1day"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.sntkTest"),
      name: CONSENT_COOKIES_NECESSARY.sntkTest,
    },
    {
      key: "9",
      label: "NEXT_LOCALE",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.nextLocale"),
      name: CONSENT_COOKIES_NECESSARY.nextLocale,
    },
    {
      key: "10",
      label: CONSENT_COOKIE_KEY,
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.cookieConsent"),
      name: CONSENT_COOKIES_NECESSARY.cookieConsent,
    },
    {
      key: "11",
      label: "user-country",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.userCountry"),
      name: CONSENT_COOKIES_NECESSARY.userCountry,
    },
  ];
};

type GetConsentCookiesDataProps = {
  t: TFunction;
  type: keyof typeof CONSENT_COOKIES_GROUPS;
};

export const getConsentCookiesData = (props: GetConsentCookiesDataProps) => {
  const { t, type } = props;

  const consentCookiesItemsMapper = {
    [CONSENT_COOKIES_GROUPS.necessary]: getCookiesNecessaryData({ t }),
    [CONSENT_COOKIES_GROUPS.statistics]: getCookiesStatisticsData({ t }),
  };

  const consentCookiesDescriptionMapper = {
    [CONSENT_COOKIES_GROUPS.necessary]: t("cookies:consentCookies.necessary.description"),
    [CONSENT_COOKIES_GROUPS.statistics]: t("cookies:consentCookies.statistics.description"),
  };

  const consentCookiesSwitcherLabelMapper = {
    [CONSENT_COOKIES_GROUPS.necessary]: t("cookies:consentCookies.necessary.tabTitle"),
    [CONSENT_COOKIES_GROUPS.statistics]: t("cookies:consentCookies.statistics.tabTitle"),
  };

  return {
    items: consentCookiesItemsMapper[type],
    description: consentCookiesDescriptionMapper[type],
    switcherLabel: consentCookiesSwitcherLabelMapper[type],
  };
};

export const consentCookiesGroupsList = [
  { key: "1", disabled: true, groupName: CONSENT_COOKIES_GROUPS.necessary },
  { key: "2", groupName: CONSENT_COOKIES_GROUPS.statistics },
];
