// Core
import { useCallback, useEffect, useState } from "react";

// Definitions
import {
  CONSENT_COOKIES_NECESSARY,
  CONSENT_COOKIES_STATISTICS,
} from "client/core/consent-cookies/models";
import type { ConsentCookiesType } from "client/core/consent-cookies/models";
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";

// Utils
import { cookiesService } from "utils/cookies";
import { CONSENT_COOKIE_KEY } from "client/core/consent-cookies/constants";
import { gtmService } from "client/utils/gtm";

type UseConsentCookiesType = {
  isOpenConsentCookies: boolean;
  onAllowAllCookies: () => void;
  onRejectAllCookies: () => void;
  setConsentCookies: (data: ConsentCookiesType) => void;
};

const defaultConsentCookiesEventData = {
  necessary: false,
  statistics: false,
};

const defaultConsentCookies: ConsentCookiesType = {
  necessary: {
    [CONSENT_COOKIES_NECESSARY.isEnabled]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthSession]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCallback]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCsrt]: true,
    [CONSENT_COOKIES_NECESSARY.kmtxSyncPi]: true,
    [CONSENT_COOKIES_NECESSARY.kmtxSync]: true,
    [CONSENT_COOKIES_NECESSARY.sntkTest]: true,
    [CONSENT_COOKIES_NECESSARY.nextLocale]: true,
    [CONSENT_COOKIES_NECESSARY.cookieConsent]: true,
    [CONSENT_COOKIES_NECESSARY.userCountry]: true,
  },
  statistics: {
    [CONSENT_COOKIES_STATISTICS.isEnabled]: true,
    [CONSENT_COOKIES_STATISTICS.ga]: true,
    [CONSENT_COOKIES_STATISTICS.gid]: true,
    [CONSENT_COOKIES_STATISTICS.gat]: true,
    [CONSENT_COOKIES_STATISTICS.visitorInfoLive]: true,
    [CONSENT_COOKIES_STATISTICS.ysc]: true,
  },
};

const getEventDataFromConsentCookies = (consentData: ConsentCookiesType) => {
  return {
    necessary: consentData.necessary?.isEnabled,
    statistics: consentData.statistics?.isEnabled,
  };
};

export const useConsentCookies = (): UseConsentCookiesType => {
  const [isOpenConsentCookies, setIsOpenConsentCookies] = useState(false);

  useEffect(() => {
    const consentCookies = cookiesService.fromJson<ConsentCookiesType>(CONSENT_COOKIE_KEY);
    const isAcceptedCookies = !!consentCookies;
    let cookieAppearanceTimeout: NodeJS.Timeout;

    const eventData = consentCookies
      ? getEventDataFromConsentCookies(consentCookies)
      : defaultConsentCookiesEventData;

    gtmService.event(GTMTypesEnum.consent_mode_def, eventData);

    if (!isAcceptedCookies) {
      cookieAppearanceTimeout = setTimeout(() => setIsOpenConsentCookies(true), 2000);
    }

    return () => clearInterval(cookieAppearanceTimeout);
  }, []);

  const setConsentCookies = useCallback((data: ConsentCookiesType) => {
    cookiesService.toJson<ConsentCookiesType>(CONSENT_COOKIE_KEY, data);
    setIsOpenConsentCookies(false);
    gtmService.event(GTMTypesEnum.consent_mode_option, getEventDataFromConsentCookies(data));
  }, []);

  const onAllowAllCookies = useCallback(() => {
    setConsentCookies(defaultConsentCookies);
  }, []);

  const onRejectAllCookies = useCallback(() => {
    setConsentCookies({
      necessary: defaultConsentCookies.necessary,
      statistics: {
        [CONSENT_COOKIES_STATISTICS.isEnabled]: false,
        [CONSENT_COOKIES_STATISTICS.ga]: false,
        [CONSENT_COOKIES_STATISTICS.gid]: false,
        [CONSENT_COOKIES_STATISTICS.gat]: false,
        [CONSENT_COOKIES_STATISTICS.visitorInfoLive]: false,
        [CONSENT_COOKIES_STATISTICS.ysc]: false,
      },
    });
  }, []);

  return {
    isOpenConsentCookies,
    onAllowAllCookies,
    onRejectAllCookies,
    setConsentCookies,
  };
};
