// Definitions
import { CONSENT_COOKIES_GROUPS } from "client/core/consent-cookies/models";
import type { ConsentCookiesType } from "client/core/consent-cookies/models";

type SetValueToCookiesGroupPropsType = {
  groupName: keyof typeof CONSENT_COOKIES_GROUPS;
  value: boolean;
};

export const setCookiesGroupValueToItems = (props: SetValueToCookiesGroupPropsType) => {
  const { value, groupName } = props;

  const itemsMapper: ConsentCookiesType = {
    statistics: {
      isEnabled: value,
      gat: value,
      gid: value,
      ysc: value,
      visitorInfoLive: value,
      ga: value,
    },
    necessary: {
      isEnabled: value,
      cookieConsent: value,
      kmtxSync: value,
      kmtxSyncPii: value,
      nextLocale: value,
      secureNextAuthCallback: value,
      secureNextAuthCsrt: value,
      secureNextAuthSession: value,
      sntkTest: value,
      userCountry: value,
    },
  };

  return itemsMapper[groupName];
};
