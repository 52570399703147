// Core
import { SyntheticEvent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Definitions
import type { ConsentCookiesType } from "client/core/consent-cookies/models";
import { CONSENT_COOKIES_GROUPS } from "client/core/consent-cookies/models";

// Components
import { Form } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Grid } from "components/ui/Grid";
import { Button } from "components/ui/Button";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { ConsentCookiesGroup } from "client/core/consent-cookies/components/consent-cookies-group";
import { DynamicImport } from "components/common/DynamicImport";
import { DecorBorder } from "components/common/DecorBorder";
import { ConsentCookiesGroupSwitcher } from "client/core/consent-cookies/components/consent-cookies-group-switcher";

// Hooks
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { consentCookiesForm } from "./config";
import { consentCookiesGroupsList } from "client/core/consent-cookies/data";

import st from "./styles.module.css";

const Accordion = DynamicImport(import("components/ui/Accordion/Collapse"), "Accordion");
const AccordionPanel = DynamicImport(import("components/ui/Accordion/Panel"), "Panel");

type ConsentCookiesFormProps = {
  onAllowAllCookies: () => void;
  onSubmit: (values: ConsentCookiesType) => void;
};

export const ConsentCookiesForm = (props: ConsentCookiesFormProps) => {
  const { onAllowAllCookies, onSubmit } = props;
  const { t } = useTranslation("cookies");
  const { md } = Grid.useBreakpoint();
  const isMobile = !md;

  const formProps = useForm<ConsentCookiesType>({
    defaultValues: consentCookiesForm.shape,
    resolver: zodResolver(consentCookiesForm.schema),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit, watch } = formProps;

  const handleSubmitForm = handleSubmit((values: ConsentCookiesType): void => {
    onSubmit(values);
  });

  const onSubmitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  const necessaryValue = watch(CONSENT_COOKIES_GROUPS.necessary);
  const statisticsValue = watch(CONSENT_COOKIES_GROUPS.statistics);

  const selectedCookiesDataAttribute = `selected_cookies%${necessaryValue.isEnabled ? "necessary" : "none"}%${statisticsValue.isEnabled ? "statistics" : "none"}%none`;

  const consentCookiesGroupsJSX = isMobile ? (
    <Accordion type="bordered">
      {consentCookiesGroupsList.map(({ groupName, disabled = false, key }) => {
        return (
          <AccordionPanel
            key={key}
            header={{
              title: <ConsentCookiesGroupSwitcher groupName={groupName} disabled={disabled} />,
            }}
          >
            <ConsentCookiesGroup key={key} groupName={groupName} disabled={disabled} />
          </AccordionPanel>
        );
      })}
    </Accordion>
  ) : (
    consentCookiesGroupsList.map(({ groupName, disabled = false, key }) => {
      return (
        <DecorBorder key={key} size="medium">
          <ConsentCookiesGroup groupName={groupName} disabled={disabled} />
        </DecorBorder>
      );
    })
  );

  return (
    <Form onFinish={onSubmitForm} className={st["consent-cookies-form"]}>
      <FormBlock>
        <FormProvider {...formProps}>
          <Col span={24}>
            <div className={st["consent-cookies-groups"]}>{consentCookiesGroupsJSX}</div>

            <Row gutter={ROW_GUTTER_TYPE.md.horizontal} justify="end" className={st.actions}>
              <Col xs={24} md={7} lg={5} className={st.action}>
                <Button
                  block
                  type="primary"
                  ghost
                  htmlType="submit"
                  data-kmtx-cookies-allow_customized={selectedCookiesDataAttribute}
                >
                  {t("consentCookies.buttons.allowSelectionCookies")}
                </Button>
              </Col>
              <Col xs={24} md={7} lg={5} className={st.action}>
                <Button
                  block
                  type="primary"
                  onClick={onAllowAllCookies}
                  data-kmtx-cookies-allow
                  data-kmtx-cookies-screen-type="custom"
                >
                  {t("consentCookies.buttons.allowCookies")}
                </Button>
              </Col>
            </Row>
          </Col>
        </FormProvider>
      </FormBlock>
    </Form>
  );
};
