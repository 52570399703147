// Core
import { useContext, useEffect } from "react";
import dayjs from "dayjs";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";
import { POPUP_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Contexts
import { PermissionContext } from "contexts/usePermissionContext";

// Utils
import { cookiesService } from "utils/cookies";
import { isValidDate } from "utils/isValidDate";
import { triggerPopupGtmEvent } from "components/common/ModalViewsSwitcher/utils/popup-gtm-events";

type UseProfileSurveyNpsProps = {
  surveyName: string;
  toggleSurveyNpsModal: (shown: boolean) => void;
  permissionName: string;
  maxTimesShow?: number;
};

export const useProfileSurveyNPS = (props: UseProfileSurveyNpsProps) => {
  const { surveyName, toggleSurveyNpsModal, maxTimesShow = 3, permissionName } = props;
  const SURVEY_LAST_SHOW_DATE_KEY = `survey_${surveyName}_last_show_date`;
  const SURVEY_AMOUNT_SHOW_DAYS_KEY = `survey_${surveyName}_amount_show_days`;

  const ability = useContext(PermissionContext);

  useEffect(() => {
    const canShowNpsModal = ability.can(PERMISSION_ACT.READ, permissionName);

    if (canShowNpsModal) {
      const amountOfSurveyShowDays = Number(cookiesService.get(SURVEY_AMOUNT_SHOW_DAYS_KEY));
      const lastShowDate = Number(cookiesService.get(SURVEY_LAST_SHOW_DATE_KEY));

      if (amountOfSurveyShowDays < maxTimesShow && isValidDate(lastShowDate)) {
        toggleSurveyNpsModal(true);
        triggerPopupGtmEvent({
          actionType: POPUP_ACTION_TYPE.nps_view,
        });

        cookiesService.set(SURVEY_AMOUNT_SHOW_DAYS_KEY, String(amountOfSurveyShowDays + 1));
        cookiesService.set(SURVEY_LAST_SHOW_DATE_KEY, String(dayjs().unix()));
      }
    }
  }, [permissionName]);
};
