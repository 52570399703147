// Core
import { useTranslation, Trans } from "next-i18next";

// Components
import { Image } from "components/ui/Image";
import { Link } from "components/common/Link";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";

// Domains
import { useProfileContext } from "hooks/useProfileContext";

// Definitions
import { POPUP_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { getSurveyNPSLink } from "data/survey";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { triggerPopupGtmEvent } from "components/common/ModalViewsSwitcher/utils/popup-gtm-events";
import { cookiesService } from "utils/cookies";

import st from "./styles.module.css";

export type SurveyNpsSpecialNodalProps = {
  surveyName: string;
  onClose?: () => void;
};

export const SurveyNpsSpecialModal = ({ onClose, surveyName }: SurveyNpsSpecialNodalProps) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const handleClickLink = () => {
    const SURVEY_AMOUNT_SHOW_DAYS_KEY = `survey_${surveyName}_amount_show_days`;
    cookiesService.set(SURVEY_AMOUNT_SHOW_DAYS_KEY, String(3));

    onClose?.();

    triggerPopupGtmEvent({
      actionType: POPUP_ACTION_TYPE.nps_click,
    });
  };

  return (
    <div className={st["survey-nps-special-modal"]}>
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
        <Col span={17}>
          <div className={st["survey-nps-special-modal-content"]}>
            <div className={st["survey-nps-special-modal-title"]}>
              <Title tag="h3" size="24" fontWeight="400" color="gray-400">
                {t("common:modals:survey-special.title")}
              </Title>
            </div>
            <div className={st["survey-nps-special-modal-text"]}>
              <Text box="full" tag="p" font="system" size="32" color="gray-200">
                <Trans
                  i18nKey={"common:modals:survey-special.text"}
                  components={{
                    bold: (
                      <Text
                        transform="up"
                        tag="p"
                        font="system"
                        fontWeight="900"
                        size="32"
                        color="blue-750"
                        box="inline"
                      />
                    ),
                  }}
                />
              </Text>
            </div>

            <div className={st["survey-nps-special-modal-link"]}>
              <Link
                fontWeight="600"
                size="16"
                type="banner"
                blank
                font="second"
                href={getSurveyNPSLink(profile?.customerId, profile?.countryCode)}
                onClick={handleClickLink}
              >
                <Text transform="up" color="blue-800" font="second" fontWeight="600">
                  {t("common:modals:survey-special.link")}
                </Text>
              </Link>
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Image
            src="/assets/images/survey-pass.png"
            width={257}
            height={322}
            objectFit="contain"
            objectPosition="right center"
            alt={t("cart:empty.imageAlt")}
            className={st["survey-image"]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={st["survey-nps-special-modal-offer"]}>
            <Text alignmentHorizontal="center" color="white-100" fontWeight="600" size="28">
              {t("common:modals:survey-special.offer")}
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
};
