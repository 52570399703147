// Definitions
import { GTMTypesEnum, POPUP_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventPopupDataType = {
  actionType: (typeof POPUP_ACTION_TYPE)[keyof typeof POPUP_ACTION_TYPE];
};

export const triggerPopupGtmEvent = (eventData: EventPopupDataType) => {
  const { actionType } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.popup, {
    actionType,
  });
};
