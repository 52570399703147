// Core
import { z } from "zod";

export const CONSENT_COOKIES_GROUPS = {
  necessary: "necessary",
  statistics: "statistics",
} as const;

export const CONSENT_COOKIES_NECESSARY = {
  isEnabled: "isEnabled",
  secureNextAuthSession: "secureNextAuthSession",
  secureNextAuthCallback: "secureNextAuthCallback",
  secureNextAuthCsrt: "secureNextAuthCsrt",
  kmtxSyncPi: "kmtxSyncPii",
  kmtxSync: "kmtxSync",
  sntkTest: "sntkTest",
  nextLocale: "nextLocale",
  cookieConsent: "cookieConsent",
  userCountry: "userCountry",
} as const;

export const CONSENT_COOKIES_STATISTICS = {
  isEnabled: "isEnabled",
  ga: "ga",
  gid: "gid",
  gat: "gat",
  visitorInfoLive: "visitorInfoLive",
  ysc: "ysc",
} as const;

export const consentCookiesSchema = z.object({
  [CONSENT_COOKIES_GROUPS.necessary]: z.object({
    [CONSENT_COOKIES_NECESSARY.isEnabled]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.secureNextAuthSession]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCallback]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCsrt]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.kmtxSyncPi]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.kmtxSync]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.sntkTest]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.nextLocale]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.cookieConsent]: z.boolean(),
    [CONSENT_COOKIES_NECESSARY.userCountry]: z.boolean(),
  }),
  [CONSENT_COOKIES_GROUPS.statistics]: z.object({
    [CONSENT_COOKIES_STATISTICS.isEnabled]: z.boolean(),
    [CONSENT_COOKIES_STATISTICS.ga]: z.boolean(),
    [CONSENT_COOKIES_STATISTICS.gid]: z.boolean(),
    [CONSENT_COOKIES_STATISTICS.gat]: z.boolean(),
    [CONSENT_COOKIES_STATISTICS.visitorInfoLive]: z.boolean(),
    [CONSENT_COOKIES_STATISTICS.ysc]: z.boolean(),
  }),
});

export type ConsentCookiesType = z.infer<typeof consentCookiesSchema>;
