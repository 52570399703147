// Core
import { cloneElement, useEffect, useState } from "react";
import cx from "classnames";

// Definitions
import { ModalProps as AntdModalProps } from "antd";

// Components
import { Modal as AntModal } from "antd";
import { Icon } from "components/ui/Icon";

// Utils
import st from "./Styles.module.less";
import { getModalWidth } from "./utils";

export type ModalProps = AntdModalProps & {
  children: React.ReactElement;
  gutter?: "none" | "small" | "middle" | "large";
  autoWidth?: boolean;
  visible?: boolean;
  isFullScreen?: boolean;
  isCentered?: boolean;
  onVisible?: () => void;
  onClose?: () => void;
  onHidden?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  modalType?: string;
  topPosition?: "small";
};
type ModalPropertiesStateType = Pick<
  ModalProps,
  "isCentered" | "modalType" | "autoWidth" | "topPosition" | "gutter"
>;

export const Modal = (props: ModalProps) => {
  const {
    children,
    visible,
    onVisible,
    onHidden,
    onClose,
    isCentered,
    zIndex = 1000,
    wrapClassName = "",
    gutter = "large",
    autoWidth = false,
    modalType,
    closable = true,
    topPosition,
  } = props;
  const [modalProperties, setModalProperties] = useState<ModalPropertiesStateType>({
    isCentered,
    modalType,
    autoWidth,
    topPosition,
    gutter,
  });

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.preventDefault();
    onClose?.();
    onHidden?.(e);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleClose(e);
    onHidden?.(e);
  };

  const handleChangeModalProperties = () => {
    setModalProperties({ isCentered, modalType, autoWidth, topPosition, gutter });
  };

  const modalWidth = getModalWidth(modalProperties.modalType, autoWidth);
  const modalStyle = cx(st.modal, {
    [st[`modal-gutter-${modalProperties.gutter}`]]: Boolean(modalProperties.gutter),
    [st[`modal-top-position-${modalProperties.topPosition}`]]: Boolean(modalProperties.topPosition),
  });

  useEffect(() => {
    if (visible) {
      handleChangeModalProperties();
      return;
    }

    setTimeout(() => {
      handleChangeModalProperties();
    }, 300);
  }, [modalType, autoWidth]);

  return (
    <AntModal
      className={modalStyle}
      width={modalWidth}
      open={visible}
      centered={modalProperties.isCentered ?? true}
      footer={null}
      destroyOnClose
      onOk={onVisible}
      onCancel={handleClose}
      closeIcon={<Icon name="ControlCloseModal" />}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
      closable={closable}
    >
      {cloneElement(children, { onClose: handleCancel })}
    </AntModal>
  );
};
