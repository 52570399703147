// Components
import { Control, Controller } from "react-hook-form";
import { InputSwitch } from "components/ui/FormFields/InputSwitch";

// Definitions
import type { InputSwitchPropsType } from "components/ui/FormFields/InputSwitch/InputSwitch.types";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormInputSwitchType = InputSwitchPropsType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormInputSwitch = (props: WrappedFormInputSwitchType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputSwitch {...rest} {...field} ref={field.ref} />}
    />
  );
};
