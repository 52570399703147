// Core
import { useTranslation } from "next-i18next";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Components
import { Layout } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Title } from "components/ui/Typography/Title";
import { Container } from "components/common/Container";
import { Logo } from "components/common/Logo";
import { Can } from "components/common/Can";
import { HeaderBanner } from "client/core/header-banner/components/header-banner";

import { useProfileContext } from "hooks/useProfileContext";

// Utils
import st from "./Styles.module.less";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";

type LayoutPrivateHeaderProps = {
  hasMandate?: boolean;
  hasPaymentAdyen?: boolean;
};

export const LayoutHeaderCart = (props: LayoutPrivateHeaderProps) => {
  const { hasMandate, hasPaymentAdyen } = props;
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const getHeaderCartTitle = () => {
    if (hasMandate) return "cart:mandate.title";
    if (hasPaymentAdyen) return "orders:payment.adyen.title";

    return "cart:pageTitle";
  };

  const titleI18nKey = getHeaderCartTitle();

  return (
    <Layout.Header className={st.header}>
      <Can I={PERMISSION_ACT.READ} this={"banner_top"}>
        {profile && <HeaderBanner />}
      </Can>
      <div className={st["header-inner"]} data-test-id="cart-header">
        <Container>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal} align="middle">
            <Col span={4}>
              <Logo type="dark" alt={t("common:firstPartPageTitle")} />
            </Col>
            <Col span={16}>
              <Title tag="h1" size="24" alignmentHorizontal="center">
                {t(titleI18nKey)}
              </Title>
            </Col>
            <Col span={4} />
          </Row>
        </Container>
      </div>
    </Layout.Header>
  );
};
