// Core
import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import dayjs from "dayjs";

// Definitions
import { POPUP_ACTION_TYPE } from "client/utils/gtm/gtm.types";
import { PERMISSION_ACT } from "client/core/profile/models";

// Contexts
import { PermissionContext } from "contexts/usePermissionContext";

// Hooks
import { useModalContext } from "hooks/useModalContext";

// Utils
import { cookiesService } from "utils/cookies";
import {
  GO_CORE_AMOUNT_SHOW_DAYS_KEY,
  GO_CORE_LAST_SHOW_DATE_KEY,
} from "client/core/go-core/constants/go-core-launch-promotion";
import { GET_ENUMS } from "utils/constants";
import { triggerPopupGtmEvent } from "components/common/ModalViewsSwitcher/utils/popup-gtm-events";
import { isValidDate } from "utils/isValidDate";
import { book } from "init/book";

type UseGoCoreLaunchPromotionProps = {
  maxTimesShow?: number;
};

export const useGoCoreLaunchPromotion = (props: UseGoCoreLaunchPromotionProps) => {
  const { setOnPageModal } = useModalContext();
  const ability = useContext(PermissionContext);

  const { maxTimesShow = 3 } = props;
  const router = useRouter();

  useEffect(() => {
    if (router.asPath !== `${book.catalog}/new`) {
      return;
    }

    const canShowModal = ability.can(PERMISSION_ACT.READ, "go_core_modal");

    if (canShowModal) {
      const amountOfSurveyShowDays = Number(cookiesService.get(GO_CORE_AMOUNT_SHOW_DAYS_KEY));
      const lastShowDate = Number(cookiesService.get(GO_CORE_LAST_SHOW_DATE_KEY));

      if (amountOfSurveyShowDays < maxTimesShow && isValidDate(lastShowDate)) {
        triggerPopupGtmEvent({
          actionType: POPUP_ACTION_TYPE.go_core_view,
        });
        setOnPageModal(GET_ENUMS.popup.goCoreLaunchPromotion, {
          visible: true,
          modalProps: {
            wrapClassName: "go-core-launch-promotion-wrapper",
            gutter: "none",
            autoWidth: true,
          },
        });

        cookiesService.set(GO_CORE_AMOUNT_SHOW_DAYS_KEY, String(amountOfSurveyShowDays + 1));
        cookiesService.set(GO_CORE_LAST_SHOW_DATE_KEY, String(dayjs().unix()));
      }
    }
  }, [router.asPath]);
};
