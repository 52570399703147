// Definitions
import {
  CONSENT_COOKIES_GROUPS,
  CONSENT_COOKIES_NECESSARY,
  CONSENT_COOKIES_STATISTICS,
  consentCookiesSchema,
  ConsentCookiesType,
} from "client/core/consent-cookies/models";

export const inputConfig = {
  [CONSENT_COOKIES_GROUPS.necessary]: {
    label: "consentCookies.inputs.necessaryCookies.label",
  },
  [CONSENT_COOKIES_GROUPS.statistics]: {
    label: "consentCookies.inputs.statisticsCookies.label",
  },
};

const defaultFormValues: ConsentCookiesType = {
  [CONSENT_COOKIES_GROUPS.necessary]: {
    [CONSENT_COOKIES_NECESSARY.isEnabled]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthSession]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCallback]: true,
    [CONSENT_COOKIES_NECESSARY.secureNextAuthCsrt]: true,
    [CONSENT_COOKIES_NECESSARY.kmtxSyncPi]: true,
    [CONSENT_COOKIES_NECESSARY.kmtxSync]: true,
    [CONSENT_COOKIES_NECESSARY.sntkTest]: true,
    [CONSENT_COOKIES_NECESSARY.nextLocale]: true,
    [CONSENT_COOKIES_NECESSARY.cookieConsent]: true,
    [CONSENT_COOKIES_NECESSARY.userCountry]: true,
  },
  [CONSENT_COOKIES_GROUPS.statistics]: {
    [CONSENT_COOKIES_STATISTICS.isEnabled]: true,
    [CONSENT_COOKIES_STATISTICS.ga]: true,
    [CONSENT_COOKIES_STATISTICS.gid]: true,
    [CONSENT_COOKIES_STATISTICS.gat]: true,
    [CONSENT_COOKIES_STATISTICS.visitorInfoLive]: true,
    [CONSENT_COOKIES_STATISTICS.ysc]: true,
  },
};

export const consentCookiesForm = {
  shape: defaultFormValues,
  schema: consentCookiesSchema,
};
