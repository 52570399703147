// Core
import { ReactElement, ReactNode } from "react";
import { useCollapse } from "react-collapsed";
import cx from "classnames";

// Components
import { Button } from "../Button";
import { Icon } from "../Icon";

// Styles
import st from "./styles.module.css";

const MORE_BUTTON_POSITION = {
  left: "left",
  center: "center",
} as const;

const ICON_POSITION = {
  right: "right",
} as const;

export type CollapsePropsType = {
  visibleContent: string | ReactNode | ReactElement[];
  children?: string | ReactElement | ReactElement[];
  collapsedText: string | ReactElement;
  expandedText: string | ReactElement;
  moreButtonPosition?: keyof typeof MORE_BUTTON_POSITION;
  iconPosition?: keyof typeof ICON_POSITION;
  gutter?: "bottom" | "";
  iconLess?: string;
  iconMore?: string;
};

export const Collapse = (props: CollapsePropsType) => {
  const {
    visibleContent,
    children,
    collapsedText,
    expandedText,
    moreButtonPosition,
    gutter = "",
    iconLess = "ControlArrowUp",
    iconMore = "ControlArrowDown",
    iconPosition,
  } = props;
  const collapseType = cx(st.collapse, {
    [st[`collapse-gutter-${gutter}`]]: !!gutter,
  });
  const moreButtonStyle = cx({
    [st["more-button-left"]]: moreButtonPosition === MORE_BUTTON_POSITION.left,
    [st[`icon-position-${iconPosition}`]]: Boolean(iconPosition),
  });
  // eslint-disable-next-line @typescript-eslint/unbound-method, jest/unbound-method
  const { getCollapseProps, isExpanded, setExpanded } = useCollapse();

  const handleToggleContent = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const iconJSX = isExpanded ? <Icon name={iconLess} /> : <Icon name={iconMore} />;

  return (
    <div className={collapseType}>
      <div className={st["visible-content"]}>{visibleContent}</div>
      {children && (
        <>
          <div {...getCollapseProps()} className={st["hidden-content"]}>
            {children}
          </div>
          <Button
            icon={iconJSX}
            className={moreButtonStyle}
            size="small"
            type="link"
            showMore
            onClick={handleToggleContent}
          >
            {isExpanded ? expandedText : collapsedText}
          </Button>
        </>
      )}
    </div>
  );
};
