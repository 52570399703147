// Definitions
import type { TFunction } from "client/utils/i18n/types";

// Utils
import { pageTitles } from "data/pages";
import { isTranslationInvalid } from "client/utils/i18n/lib";

export const getPageTitle = ({
  t,
  title,
  pageRoute,
}: {
  t: TFunction;
  title?: string;
  pageRoute?: string;
}) => {
  let pageTitlePrefix = t("common:firstPartPageTitle");

  if (isTranslationInvalid(pageTitlePrefix)) {
    pageTitlePrefix = "AUTODOC PRO";
  }

  if (title) {
    return `${pageTitlePrefix} | ${title}`;
  }

  if (!pageRoute) {
    return pageTitlePrefix;
  }

  const dynamicPageTitleKey = pageTitles[pageRoute];

  if (!dynamicPageTitleKey) {
    return pageTitlePrefix;
  }

  const dynamicPageTitle = t(dynamicPageTitleKey);

  if (isTranslationInvalid(dynamicPageTitle)) {
    return pageTitlePrefix;
  }

  return `${pageTitlePrefix} | ${dynamicPageTitle}`;
};
