// Core
import { useState } from "react";

// Domains
import { useProfileSurveyNPS } from "client/core/profile/hooks/useProfileSurveyNPS";

// Components
import { Modal } from "components/ui/Modal";
import { Can } from "components/common/Can";
import { ModalSurveyNps } from "components/common/ModalViews/SurveyNps";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

export const SurveyNps = () => {
  const surveyName = "n_24";

  const [surveyNpsOpen, setSurveyNpsOpen] = useState(false);

  const handleCloseModal = () => {
    setSurveyNpsOpen(false);
  };

  useProfileSurveyNPS({
    surveyName,
    toggleSurveyNpsModal: setSurveyNpsOpen,
    permissionName: "nps_survey_modal",
  });

  return (
    <Can I={PERMISSION_ACT.READ} this="nps_survey_modal">
      <Modal
        visible={surveyNpsOpen}
        onClose={handleCloseModal}
        wrapClassName="survey-wrapper"
        gutter="none"
        autoWidth
      >
        <ModalSurveyNps onClose={handleCloseModal} surveyName={surveyName} />
      </Modal>
    </Can>
  );
};
