// Core
import cx from "classnames";

// Definitions
import type { ReactNode } from "react";

// Utils
import st from "./Styles.module.less";

type DecorBorderProps = {
  children: ReactNode;
  className?: string;
  size?: "default" | "small" | "medium" | "large";
  noGutter?: "top" | "bottom" | "horizontal" | "vertical" | "";
  noRadius?: "default" | "all" | "top" | "bottom";
};

export const DecorBorder = (props: DecorBorderProps) => {
  const { children, className, size = "medium", noGutter = "", noRadius = "default" } = props;

  const decorBorderStyles = cx(
    st["decor-border"],
    {
      [st[`decor-border-size-${size}`]]: size,
    },
    { [st[`decor-border-no-gutter-${noGutter}`]]: noGutter },
    { [st[`decor-border-no-radius-${noRadius}`]]: noRadius },
    className,
  );

  return <div className={decorBorderStyles}>{children}</div>;
};
