// Core
import { FC, ReactElement } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";

// Utils
import { default as iconSpriteStaticImg } from "components/ui/Icon/assets/sprite.svg";
import { getPageTitle } from "./utils";
import { useTranslation } from "client/utils/i18n/client";

type PageProps = {
  children: ReactElement;
  title?: string;
};

export const Page: FC<PageProps> = ({ children, title }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const pageTitle = getPageTitle({ t, title, pageRoute: router.route });

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta
          name="viewport"
          content="width=device-width,
          initial-scale=1,
          shrink-to-fit=no, user-scalable=0"
        />
        <link as="image" href={(iconSpriteStaticImg as { src: string }).src} />
      </Head>
      {children}
      <Script src="/genesys/cxbus.min.js" strategy="lazyOnload" />
      <Script src="/genesys/genesys.js" strategy="lazyOnload" />
    </>
  );
};
