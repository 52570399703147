// Core
import { useState } from "react";

// Domains
import { useProfileSurveyNPS } from "client/core/profile/hooks/useProfileSurveyNPS";

// Components
import { Modal } from "components/ui/Modal";
import { Can } from "components/common/Can";
import { SurveyNpsSpecialModal } from "client/core/survey-nps/components/survey-nps-special-modal";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

export const SurveyNpsSpecial = () => {
  const surveyName = "n_24_1";

  const [surveyNpsOpen, setSurveyNpsOpen] = useState(false);

  const handleCloseModal = () => {
    setSurveyNpsOpen(false);
  };

  useProfileSurveyNPS({
    surveyName,
    toggleSurveyNpsModal: setSurveyNpsOpen,
    permissionName: "nps_survey_modal_special",
  });

  return (
    <Can I={PERMISSION_ACT.READ} this="nps_survey_modal_special">
      <Modal
        visible={surveyNpsOpen}
        onClose={handleCloseModal}
        wrapClassName="survey-wrapper"
        gutter="none"
        autoWidth
      >
        <SurveyNpsSpecialModal onClose={handleCloseModal} surveyName={surveyName} />
      </Modal>
    </Can>
  );
};
