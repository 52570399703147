// Core
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

// Components
import { Text } from "components/ui/Typography/Text";
import { Collapse } from "components/ui/Collapse";
import { Grid } from "components/ui/Grid";
import { ConsentCookiesGroupItem } from "client/core/consent-cookies/components/consent-cookies-group-item";
import { ConsentCookiesGroupSwitcher } from "client/core/consent-cookies/components/consent-cookies-group-switcher";

// Hooks
import { useTranslation } from "client/utils/i18n/client";

// Definitions
import { CONSENT_COOKIES_GROUPS } from "client/core/consent-cookies/models";
import type { ConsentCookiesType } from "client/core/consent-cookies/models";

// Utils
import { getConsentCookiesData } from "client/core/consent-cookies/data";
import st from "./styles.module.css";

type ConsentCookiesGroupProps = {
  groupName: keyof typeof CONSENT_COOKIES_GROUPS;
  disabled?: boolean;
};

export const ConsentCookiesGroup = (props: ConsentCookiesGroupProps) => {
  const { groupName, disabled = false } = props;

  const { t } = useTranslation();

  const { md } = Grid.useBreakpoint();
  const isMobile = !md;

  const { control, watch } = useFormContext<ConsentCookiesType>();
  const groupValue = watch(groupName);

  const { description, items } = getConsentCookiesData({ type: groupName, t });

  const cookiesGroupItemsJSX = useMemo(() => {
    return items.map((item) => {
      const { type, vendor, description: desc, name, key, expiry, label } = item;

      return (
        <ConsentCookiesGroupItem
          key={key}
          name={`${groupName}.${name}`}
          label={label}
          vendor={vendor}
          expiry={expiry}
          type={type}
          description={desc}
          isGroupValueEnabled={groupValue.isEnabled}
          disabled={disabled}
        />
      );
    });
  }, [control, items]);

  if (isMobile) {
    return (
      <div className={st["consent-cookies-group"]}>
        <div className={st["consent-cookies-group-content"]}>
          <Text size="14">{description}</Text>
          <div className={st["consent-cookies-collapse-content"]}>{cookiesGroupItemsJSX}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={st["consent-cookies-group"]}>
      <ConsentCookiesGroupSwitcher groupName={groupName} disabled={disabled} />
      <div className={st["consent-cookies-group-content"]}>
        <Collapse
          visibleContent={description}
          collapsedText={t("cookies:consentCookies.buttons.showDetails")}
          expandedText={t("cookies:consentCookies.buttons.showDetails")}
          iconPosition="right"
        >
          <div className={st["consent-cookies-collapse-content"]}>{cookiesGroupItemsJSX}</div>
        </Collapse>
      </div>
    </div>
  );
};
